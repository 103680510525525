@tailwind base;

@layer base {
  body {
    @apply h-screen;
    @apply bg-main-bg;
    @apply text-main-text;
  }

  #root {
    @apply h-full;
  }
}

@tailwind components;
@tailwind utilities;

* {
  font-family: "consolas", monospace;
}